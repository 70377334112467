// src/pages/Services.js

import React, { useState, useEffect } from "react";
 
import { Helmet } from "react-helmet";
import apron from "../images/AI-image/apron.png";
import gloves from "../images/AI-image/gloves.png";
import goggles from "../images/AI-image/goggles.png";
import helmet from "../images/AI-image/helmet.png";
import mask from "../images/AI-image/mask.png";
import mop from "../images/AI-image/mop.png";
import rubber from "../images/AI-image/ruber.png";
import surgicle from "../images/AI-image/surgicle.png";
import raincort from "../images/AI-image/raincort.png";
import safetyshooe from "../images/AI-image/safetyboot.png";
import blackBoot from "../images/AI-image/boot2.jpg";

const services = [
  {
    id: 1,
    name: "Gloves",
    description: "High-quality gloves for various safety needs.",
    image: gloves,
  },
  {
    id: 2,
    name: "Masks",
    description: "Comfortable and reliable masks for protection.",
    image: mask,
  },
  {
    id: 3,
    name: "Rubber Bands",
    description: "Durable rubber bands for multiple uses.",
    image: rubber,
  },
  {
    id: 4,
    name: "Aprons",
    description: "Protective aprons for various tasks.",
    image: apron,
  },
  {
    id: 5,
    name: "Goggles",
    description: "Protective goggles for various tasks.",
    image: goggles,
  },
  {
    id: 6,
    name: "Mops",
    description: "High-quality mops for effective cleaning.",
    image: mop,
  },
  {
    id: 7,
    name: "Helmet",
    description: "Protective helmets for various tasks.",
    image: helmet,
  },
  {
    id: 8,
    name: "Surgical Gloves",
    description: "Protective surgical gloves for medical use.",
    image: surgicle,
  },
  {
    id: 9,
    name: "Raincoat with Trouser",
    description: "Protective raincoat with trouser.",
    image: raincort,
  },
  {
    id: 10,
    name: "Safety Shoes",
    description: "Protective safety shoes for various tasks.",
    image: safetyshooe,
  },
  {
    id: 11,
    name: "Boots (Black & Yellow Available)",
    description: "Protective boots available in black and yellow.",
    image: blackBoot,
  },
];

const Services = () => {
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      try{
         (window.adsbygoogle = window.adsbygoogle || []).push({});
      }
      catch(e)
      {
        console.log(e);
      }
      setShowScrollTopButton(window.scrollY > 200);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="min-h-screen py-12 text-gray-100 bg-gray-100">
    
      <Helmet>
        <title>Akila Suppliers - Our Services</title>
        <meta
          name="description"
          content="Explore a variety of safety items including gloves, rubber bands, aprons, raincoats, boots, and more at Akila Suppliers. Discover our range of high-quality safety products at competitive prices."
        />
        <meta
          name="keywords"
          content="safety items, gloves, rubber bands, aprons, raincoats, boots, protective gear, Akila Suppliers"
        />
        <meta name="robots" content="index,follow" />
        <meta property="og:title" content="Akila Suppliers - Our Services" />
        <meta
          property="og:description"
          content="Discover our extensive range of safety items at Akila Suppliers. From gloves and rubber bands to aprons and safety boots, find high-quality products tailored to your needs."
        />
        <meta property="og:url" content="https://akilasuppliers.com/services" />
        <meta
          property="og:image"
          content="https://akilasuppliers.com/images/AI-image/service-preview.png" // Update with an appropriate image URL
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Akila Suppliers - Our Services" />
        <meta
          name="twitter:description"
          content="Explore our range of safety items including gloves, rubber bands, aprons, raincoats, and more at Akila Suppliers. High-quality products at the best prices."
        />
        <meta
          name="twitter:image"
          content="https://akilasuppliers.com/images/AI-image/service-preview.png" // Update with an appropriate image URL
        />
        <meta property="og:title" content="Akila Suppliers" />
        <meta
          property="og:description"
          content="Explore safety items such as gloves, masks, and aprons."
        />
        <meta
          property="og:image"
          content="https://www.facebook.com/share/p/zHwm3b4w8JTcwHMb/?mibextid=qi2Omg"
        />
        <meta property="og:url" content="https://akilasuppliers.com/" />

        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3554835742821865"
          crossorigin="anonymous"
        ></script>
      </Helmet>

      {/* Order Process Section */}
      <div className="max-w-6xl p-8 mx-auto mt-0 bg-gray-800 rounded-lg shadow-lg">
        <h2 className="mb-8 text-4xl font-extrabold text-center text-yellow-400">
          Order Process
        </h2>
        <p className="mb-12 text-lg text-center">
          Our order process is simple and straightforward. Follow the steps
          below to place your order:
        </p>
        <div className="space-y-8">
          {[
            {
              step: 1,
              title: "Select Your Items",
              description:
                "Browse through our selection of safety items and choose the products you need.",
            },
            {
              step: 2,
              title: "Call Us",
              description: "Call us to know our items' prices.",
            },
            {
              step: 3,
              title: "If You Agree, Enter Your Details",
              description:
                "Provide your contact information and shipping address.",
            },
            {
              step: 4,
              title: "Talk With Our Member",
              description:
                "Select one method to visit our place or courier the order.",
            },
          ].map(({ step, title, description }) => (
            <div key={step} className="flex items-center">
              <div className="flex-shrink-0">
                <span className="flex items-center justify-center w-12 h-12 text-xl font-bold text-gray-900 bg-yellow-400 rounded-full">
                  {step}
                </span>
              </div>
              <div className="ml-4">
                <h3 className="mb-2 text-2xl font-semibold text-yellow-300">
                  {title}
                </h3>
                <p className="text-gray-300">{description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Services Section */}
      <div className="max-w-6xl p-8 mx-auto bg-gray-800 rounded-lg shadow-lg">
        <h1 className="mb-8 text-5xl font-extrabold text-center text-yellow-400">
          Our Services
        </h1>
        <p className="mb-12 text-lg text-center">
          At Akila Suppliers, we provide a range of high-quality safety items to
          meet your needs. Explore our selection below to find the perfect
          products for you.
          <strong> Call us to know more about our best prices!</strong>
        </p>
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3">
          {services.map((service) => (
            <div
              key={service.id}
              className="overflow-hidden transition-transform transform bg-gray-700 rounded-lg shadow-lg hover:scale-105"
            >
              <img
                src={service.image}
                alt={service.name}
                className="object-cover w-full h-48 transition-transform duration-300 ease-in-out hover:scale-110"
              />
              <div className="p-6">
                <h2 className="mb-3 text-2xl font-semibold text-yellow-300">
                  {service.name}
                </h2>
                <p className="text-gray-300">{service.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Scroll to Top Button */}
      {showScrollTopButton && (
        <button
          onClick={scrollToTop}
          className="fixed p-3 text-white transition duration-300 bg-blue-500 rounded-full shadow-lg bottom-4 right-4 hover:bg-blue-600"
        >
          ↑
        </button>
      )}
    </div>
  );
};

export default Services;
