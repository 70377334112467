import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
 
const ContactUs = () => {
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState(""); // Status for success/failure messages

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        try{
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
        catch(e)
        {
          console.log(e);
        }
        setShowScrollTopButton(true);
      } else {
        setShowScrollTopButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Send POST request to backend
      const response = await axios.post(
        "http://localhost:5005/api/contact",
        formData
      );
      // Update status based on response
      if (response.status === 200) {
        setStatus("Message sent successfully!");
        setFormData({
          name: "",
          email: "",
          message: "",
        });
      }
    } catch (error) {
      setStatus("Failed to send message.");
      console.log(error.message);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="min-h-screen py-12 bg-gray-100">
     
      <Helmet>
        <title>Contact Us - Akila Suppliers</title>
        <meta
          name="description"
          content="Get in touch with Akila Suppliers for safety items including gloves, rubber bands, aprons, raincoats, surgical gloves, cotton gloves, boots, serviettes, and more. Contact us for the best affordable prices!"
        />
        <meta
          name="keywords"
          content="Akila Suppliers contact, safety items contact, gloves supplier contact, rubber bands supplier contact, aprons supplier contact"
        />
        <meta name="robots" content="index,follow" />
        <meta property="og:title" content="Contact Us - Akila Suppliers" />
        <meta
          property="og:description"
          content="Reach out to Akila Suppliers for high-quality safety items at competitive prices."
        />
        <meta
          property="og:image"
          content="https://example.com/contact-us-og-image.jpg"
        />
        <meta
          property="og:url"
          content="https://akilasuppliers.com/contact-us"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contact Us - Akila Suppliers" />
        <meta
          name="twitter:description"
          content="Contact Akila Suppliers for safety items like gloves, rubber bands, aprons, and more."
        />
        <meta
          name="twitter:image"
          content="https://example.com/contact-us-twitter-image.jpg"
        />
        <meta property="og:title" content="Akila Suppliers" />
        <meta
          property="og:description"
          content="Explore safety items such as gloves, masks, and aprons."
        />
        <meta
          property="og:image"
          content="https://www.facebook.com/share/p/zHwm3b4w8JTcwHMb/?mibextid=qi2Omg"
        />
        <meta property="og:url" content="https://akilasuppliers.com/" />

        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3554835742821865"
          crossorigin="anonymous"
        ></script>
      </Helmet>

      <div className="max-w-4xl p-8 mx-auto bg-gray-800 shadow-2xl rounded-xl">
        {/* Map */}
        <div className="relative mb-12">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3960.9206552671067!2d80.10008972705086!3d6.9000926647054195!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae3ad0070d82331%3A0x4823cd01ba35df81!2sAkila%20Suppliers!5e0!3m2!1sen!2slk!4v1723784999765!5m2!1sen!2slk"
            width="100%"
            height="400"
            style={{ border: "0" }}
            allowFullScreen
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className="rounded-lg"
          ></iframe>
          <div className="absolute inset-0 rounded-lg bg-gradient-to-t from-gray-900 via-transparent to-transparent"></div>
        </div>

        <h1 className="mb-8 text-4xl font-bold text-center text-white">
          Contact Us
        </h1>

        {/* Contact Information */}
        <div className="space-y-12">
          {/* Contact Numbers */}
          <div className="flex items-center space-x-6">
            <div className="flex-shrink-0">
              <svg
                className="w-8 h-8 text-yellow-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 10h4a1 1 0 011 1v4a1 1 0 01-1 1H3a1 1 0 01-1-1v-4a1 1 0 011-1z"
                />
              </svg>
            </div>
            <div>
              <h2 className="mb-2 text-2xl font-semibold text-white">
                Contact Numbers
              </h2>
              <ul className="text-gray-300">
                <li>
                  Phone-01: <a href="tel:+94717009059">0717009059</a>
                </li>
                <li>
                  Phone-02: <a href="tel:+94718824328"> 0707824328</a>
                </li>
              </ul>
            </div>
          </div>

          {/* Address */}
          <div className="flex items-center space-x-6">
            <div className="flex-shrink-0">
              <svg
                className="w-8 h-8 text-yellow-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 12h18m-6-6v12m0-6H3m18 0h-6"
                />
              </svg>
            </div>
            <div>
              <h2 className="mb-2 text-2xl font-semibold text-white">
                Address
              </h2>
              <p className="text-gray-300">75/1 Niripola, Hanwella</p>
            </div>
          </div>

          {/* Email */}
          <div className="flex items-center space-x-6">
            <div className="flex-shrink-0">
              <svg
                className="w-8 h-8 text-yellow-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13h14l-7 7-7-7z"
                />
              </svg>
            </div>
            <div>
              <h2 className="mb-2 text-2xl font-semibold text-white">Email</h2>
              <ul className="text-gray-300">
                <li>
                  General Inquiries:{" "}
                  <a href="mailto:akilasuppliers@gmail.com">
                    akilasuppliers@gmail.com
                  </a>
                </li>
                <li>
                  Support:{" "}
                  <a href="mailto:ravinduesh@gmail.com">ravinduesh@gmail.com</a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Contact Form */}
        <div className="mt-12">
          <h2 className="mb-6 text-3xl font-semibold text-center text-white">
            Send Us a Message
          </h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
              <div className="flex flex-col">
                <label className="mb-2 text-lg font-semibold text-gray-300">
                  Your Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="px-4 py-3 text-white bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:border-yellow-400"
                  required
                />
              </div>
              <div className="flex flex-col">
                <label className="mb-2 text-lg font-semibold text-gray-300">
                  Your Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="px-4 py-3 text-white bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:border-yellow-400"
                  required
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label className="mb-2 text-lg font-semibold text-gray-300">
                Message
              </label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="px-4 py-3 text-white bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:border-yellow-400"
                rows="6"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full px-6 py-3 font-semibold text-gray-900 bg-yellow-400 rounded-lg hover:bg-yellow-500 focus:outline-none"
            >
              Send Message
            </button>
            {status && (
              <div
                className={`mt-4 text-lg font-semibold ${
                  status.includes("success") ? "text-green-400" : "text-red-400"
                }`}
              >
                {status}
              </div>
            )}
          </form>
        </div>
      </div>

      {/* Scroll to Top Button */}
      {showScrollTopButton && (
        <button
          onClick={scrollToTop}
          className="fixed p-3 text-white transition duration-300 bg-blue-500 rounded-full shadow-lg bottom-4 right-4 hover:bg-blue-600"
        >
          ↑
        </button>
      )}
    </div>
  );
};

export default ContactUs;
