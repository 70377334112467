import React, { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./component/Footer";
import Header from "./component/Header";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Ruber from "./pages/Ruber";
import Services from "./pages/Services";
import ContactUs from "./pages/ContactUs";
import ItemDetail from "./pages/ItemDetail";
import Gloves from "./pages/Gloves";
import Privacy from "./pages/Privacy";
import Notification from "./component/Notification";
import WhatsAppChat from "./component/WhatsAppChat";
import { Helmet } from "react-helmet";

function App() {
  const [showNotification, setShowNotification] = useState(false);

  const handleRedirect = () => {
    setShowNotification(true);
  };

  const handleConfirm = () => {
    // Open the Messenger link in a new tab
    window.open("https://m.me/430301380158982", "_blank");
  };

  const handleClose = () => {
    setShowNotification(false);
  };

  return (
    <div>
      <Router>
        <Header />

        <h3 className="text-red-600 bg-green-400">
          Hurry Up! More offers, call now- 0717009059{" "}
          <b>More than five years trusted</b> (safety items supplier)
        </h3>

        {/* Messenger Button */}
        <button
          onClick={handleRedirect}
          className="px-6 py-3 font-semibold text-white transition duration-300 ease-in-out transform bg-blue-500 rounded-lg shadow-lg hover:bg-blue-600 hover:scale-105 focus:outline-none focus:ring focus:ring-blue-300"
        >
          Live Chat 
        </button>
        
        <WhatsAppChat/>

        {showNotification && (
          <Notification
            message="Redirecting to Messenger..."
            onClose={handleClose}
            onConfirm={handleConfirm}
          />
        )}

        <title>Safety Items-Glove</title>
        <Helmet>
          <meta
            name="description"
            content="Safety items: gloves, rubber bands, aprons, raincoats, surgical gloves, cotton gloves, boots, serviette and all other safety items. Best affordable prices."
          />
          <meta
            name="keywords"
            content="safety items gloves, rubber bands, aprons, raincoats, surgical gloves, cotton gloves, boots, serviette and all other safety items. Best affordable prices."
          />
          <meta property="og:title" content="Akila Suppliers" />
          <meta
            property="og:description"
            content="Explore safety items such as gloves, masks, and aprons."
          />
          <meta
            property="og:image"
            content="https://www.facebook.com/share/p/zHwm3b4w8JTcwHMb/?mibextid=qi2Omg"
          />
          <meta property="og:url" content="https://akilasuppliers.com/" />
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3554835742821865"
            crossorigin="anonymous"
          ></script>
        </Helmet>

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/ruber-page" element={<Ruber />} />
          <Route path="/item/:id" element={<ItemDetail />} />
          <Route path="/gloves-page" element={<Gloves />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
