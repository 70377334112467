import React from "react";

const WhatsAppChat = () => {
  const phoneNumber = "94767399304"; // Replace with your actual phone number (Sri Lanka format)

  return (
    <div className="fixed z-50 transform -translate-y-1/2 right-4 top-1/2">
      <a
        href={`https://wa.me/${phoneNumber}`}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center justify-center text-white transition duration-300 bg-green-500 rounded-full shadow-lg w-14 h-14 hover:bg-green-600"
      >
        <svg
          className="w-8 h-8"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M12 0C5.371 0 0 5.371 0 12c0 2.117.554 4.174 1.605 5.986L0 24l6.313-1.615A11.951 11.951 0 0 0 12 24c6.629 0 12-5.371 12-12S18.629 0 12 0zm6.411 17.28c-.321.897-1.608 1.646-2.468 1.753-.657.08-1.448.118-4.235-1.366-3.557-2.011-5.09-6.268-5.241-6.554-.152-.287-1.261-2.117-1.261-4.043C5.205 6.106 6.08 5.91 6.4 5.869c.42-.055.899-.046 1.291-.046.392 0 .875.016 1.127.019.252.003.59-.054.898.536.347.644.717 1.442.81 1.546.09.104.09.196.016.315-.073.12-.175.207-.262.311s-.247.252-.373.379c-.125.127-.254.262-.169.446s.685 1.143 1.477 1.845c1.012.891 1.801 1.171 2.056 1.298s.316.101.43-.064c.115-.166.493-.571.625-.767.133-.195.26-.161.448-.097s1.161.548 1.362.646c.201.098.335.147.386.228.052.081.052.463-.27.897z" />
        </svg>
      </a>
    </div>
  );
};

export default WhatsAppChat;
