import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import items from "../data/ItemData";
import { Helmet } from "react-helmet";

const ItemDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const item = items.find((item) => item.id === parseInt(id));

  useEffect(() => {
    // Scroll to the top of the page when the component is mounted
    try{
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
    catch(e)
    {
      console.log(e);
    }
    window.scrollTo(0, 0);
  }, []);

  if (!item) {
    return (
      <div className="mt-10 text-center text-red-500">Item not found!</div>
    );
  }

  const handleClose = () => {
    navigate(-1); // This will take the user back to the previous page
  };

  return (
    <div className="relative max-w-2xl p-6 mx-auto bg-white rounded-lg shadow-lg">
      
      {/* Close Button */}
      <button
        onClick={handleClose}
        className="absolute top-0 right-0 p-2 m-2 text-gray-200 transition duration-300 bg-gray-800 rounded-full hover:bg-gray-600"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          className="w-5 h-5"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>

      <img
        src={item.image}
        alt={item.name}
        className="object-cover w-full h-64 rounded-t-lg"
      />
      <Helmet>
        <title>Akila Suppliers - {item.name} Details</title>
        <meta
          name="description"
          content={`Discover the details of ${item.name} at Akila Suppliers. ${
            item.description
          }. Available sizes: ${item.sizes.join(", ")}. ${
            item.inStock ? "In Stock" : "Out of Stock"
          } now.`}
        />
        <meta
          name="keywords"
          content={`safety items, ${item.name}, ${item.sizes.join(
            ", "
          )}, gloves, rubber bands, aprons, raincoats, boots, Akila Suppliers`}
        />
        <meta name="robots" content="index,follow" />
        <meta
          property="og:title"
          content={`Akila Suppliers - ${item.name} Details`}
        />
        <meta
          property="og:description"
          content={`Explore the features of ${item.name} at Akila Suppliers. ${
            item.description
          }. Sizes available: ${item.sizes.join(", ")}.`}
        />
        <meta property="og:image" content={item.image} />
        <meta
          property="og:url"
          content={`https://akilasuppliers.com/item/${item.id}`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`Akila Suppliers - ${item.name} Details`}
        />
        <meta
          name="twitter:description"
          content={`Learn more about ${item.name} at Akila Suppliers. ${
            item.description
          }. Sizes available: ${item.sizes.join(", ")}.`}
        />
        <meta name="twitter:image" content={item.image} />

        <meta property="og:title" content="Akila Suppliers" />
        <meta
          property="og:description"
          content="Explore safety items such as gloves, masks, and aprons."
        />
        <meta
          property="og:image"
          content="https://www.facebook.com/share/p/zHwm3b4w8JTcwHMb/?mibextid=qi2Omg"
        />
        <meta property="og:url" content="https://akilasuppliers.com/" />

        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3554835742821865"
          crossorigin="anonymous"
        ></script>
      </Helmet>

      <div className="p-4">
        <h2 className="mb-2 text-2xl font-bold">{item.name}</h2>
        <p className="mb-4 text-gray-700">{item.description}</p>
        <div className="mb-2 text-gray-600">
          <span className="font-semibold">Sizes:</span> {item.sizes.join(", ")}
        </div>
        <div className="text-gray-600">
          <span className="font-semibold">In Stock:</span>{" "}
          {item.inStock ? "Yes" : "No"}
        </div>
        <div className="mt-6 space-x-2">
          <a
            href="tel:+94717009059"
            className="px-4 py-2 text-white transition duration-300 bg-blue-500 rounded hover:bg-blue-600"
          >
            Call to Order
          </a>
          <a
            href="tel:+94717009059"
            className="px-4 py-2 text-white transition duration-300 bg-blue-500 rounded hover:bg-blue-600"
          >
            Inquiry
          </a>
        </div>
      </div>
    </div>
  );
};

export default ItemDetail;
