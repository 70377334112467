import HouseholdImg from "../images/gloves/HouseHold.jpeg";
import HouseholdImg2 from "../images/gloves/HouseHoldGloves-02.jpeg";
import dotGloves from "../images/gloves/dotGloves.jpeg";
import Surgicle from "../images/gloves/glvoes.jpeg";

import CottonGlove from "../images/new/cotton-new.png";
import DisPolythene from "../images/new/disposable-polythene-glove.png";
import Dynamaa from "../images/new/dynamaa-glove.png";
import NonMedicle from "../images/new/non-medical-glove.png";
import RubberBlack from "../images/new/Rubber-black-glove.png";
import RubberNitile from "../images/new/Rubber-nitrile-glove.png";
import RubberYellow from "../images/new/Rubber-yellow-glove.png";

const gloves = [
  {
    id: 1,
    image: HouseholdImg,
    name: "Premium Rubber Gloves Household",
    description:
      "Experience unmatched durability and comfort with these high-quality rubber gloves, perfect for all your household tasks. Available now at an incredibly affordable price!",
    sizes: ["S", "M", "L"],
    inStock: true,
  },
  {
    id: 2,
    image: HouseholdImg2,
    name: "Premium Rubber Gloves ",
    description:
      "Keep your hands safe and dry with these premium rubber gloves, designed for style and protection in any situation. Grab yours today at an unbeatable price!",
    sizes: ["S", "M", "L"],
    inStock: true,
  },
  {
    id: 3,
    image: dotGloves,
    name: "Dot Gloves Jacket",
    description:
      "Stay comfortable and protected during work with these dot gloves, combining grip and flexibility for all tasks. Get them now at a price you won't believe!",
    sizes: ["S", "M", "L"],
    inStock: true,
  },
  {
    id: 4,
    image: Surgicle,
    name: "Surgical Glove ",
    description:
      "Ensure your safety with these surgical gloves, offering premium protection and comfort . Available at a very affordable price!",
    sizes: ["S", "M", "L"],
    inStock: true,
  },
  
  {
    id: 19,
    image: CottonGlove,
    name: "Cotton Glove PVC ",
    description:
      "Ensure your safety with these Cotton gloves, offering premium protection and comfort . Available at a very affordable price! less than Rs.200",
    sizes: ["M", "L"],
    inStock: true,
  },
  {
    id: 20,
    image: DisPolythene,
    name: "Disposable Polythene Glove ",
    description:
      "Ensure your safety with these Disposable Polythene gloves, offering premium protection and comfort . Available at a very affordable price!",
    sizes: ["S", "M", "L"],
    inStock: true,
  },
  {
    id: 21,
    image: Dynamaa,
    name: "Dyneema Glove ",
    description:
      "Ensure your safety with these Dyneema gloves, offering premium protection and comfort . Available at a very affordable price!",
    sizes: ["M", "L"],
    inStock: true,
  },
  {
    id:22,
    image: NonMedicle,
    name: " Non Medicle Surgical Glove ",
    description:
      "Ensure your safety with these Non Medicle surgical gloves, offering premium protection and comfort . Available at a very affordable price!",
    sizes: ["S", "M", "L"],
    inStock: true,
  },
  {
    id: 23,
    image: RubberBlack,
    name: " Rubber Black Glove ",
    description:
      "Ensure your safety with these Non Rubber  gloves, offering premium protection and comfort . Available at a very affordable price!",
    sizes: [ "M", "L"],
    inStock: true,
  },
  {
    id: 24,
    image: RubberNitile,
    name: " Nitrile Glove ",
    description:
      "Ensure your safety with these Nitrile gloves, offering premium protection and comfort . Available at a very affordable price!",
    sizes: [ "M", "L"],
    inStock: true,
  },
  {
    id: 25,
    image: RubberYellow,
    name: " Rubber Yellow Glove ",
    description:
      "Ensure your safety with these Rubber Yello gloves, offering premium protection and comfort . Available at a very affordable price!",
    sizes: [ "M", "L"],
    inStock: true,
  },
];

export default gloves;
