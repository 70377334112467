import React from "react";

function Notification({ message, onClose, onConfirm }) {
  return (
    <div
      style={{
        position: "fixed",
        top: "20px",
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: "#333",
        color: "#fff",
        padding: "10px 20px",
        borderRadius: "5px",
        zIndex: 1000,
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <span>{message}</span>
      <button
        style={{
          backgroundColor: "#ff4d4d",
          color: "#fff",
          border: "none",
          borderRadius: "3px",
          cursor: "pointer",
          padding: "5px 10px",
        }}
        onClick={onClose}
      >
        Close
      </button>
      <button
        style={{
          backgroundColor: "#4CAF50",
          color: "#fff",
          border: "none",
          borderRadius: "3px",
          cursor: "pointer",
          padding: "5px 10px",
        }}
        onClick={onConfirm}
      >
        Go Now
      </button>
    </div>
  );
}

export default Notification;
