// src/pages/Ruber.js

import React, { useEffect, useState } from "react";
 
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Item from "../pages/Item";
import items from "../data/rubberBands";
import RuberDetail from "./RuberDetail";
import "../App.css";
import { Helmet } from "react-helmet";
import SearchBar from "../component/SearchBar";
const Ruber = () => {
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);
  // State for search query and scroll to top button visibility
  const [searchQuery, setSearchQuery] = useState("");
  

  // Handle search query
  const handleSearch = (query) => {
    setSearchQuery(query.toLowerCase());
  };

  // Filter items based on search query
  const filteredItems = items.filter((item) =>
    item.name.toLowerCase().includes(searchQuery)
  );

  // Scroll to top button visibility handler
  useEffect(() => {
    const handleScroll = () => {

      try{
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }
      catch(e)
      {
        console.log(e);
      }
      setShowScrollTopButton(window.scrollY > 200);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Scroll to top functionality
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="min-h-screen py-10 bg-gray-100">
    
      <Helmet>
        <title>Akila Suppliers - Rubber Bands Collection</title>
        <meta
          name="description"
          content="Explore our wide range of durable and versatile rubber bands at Akila Suppliers. We offer various types for different needs, ensuring you find the perfect fit for your requirements."
        />
        <meta
          name="keywords"
          content="rubber bands, Akila Suppliers, office supplies, elastic bands, rubber bands collection, versatile rubber bands"
        />
        <meta name="robots" content="index,follow" />
        <meta
          property="og:title"
          content="Akila Suppliers - Rubber Bands Collection"
        />
        <meta
          property="og:description"
          content="Discover our extensive collection of rubber bands. Find durable and versatile bands suitable for a variety of applications. Shop now at Akila Suppliers."
        />
        <meta
          property="og:url"
          content="https://akilasuppliers.com/rubber-bands"
        />
        <meta
          property="og:image"
          content="URL_TO_IMAGE_RELATED_TO_RUBBER_BANDS"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Akila Suppliers - Rubber Bands Collection"
        />
        <meta
          name="twitter:description"
          content="Browse our selection of rubber bands at Akila Suppliers. Explore different types and sizes to suit your needs."
        />
        <meta
          name="twitter:image"
          content="URL_TO_IMAGE_RELATED_TO_RUBBER_BANDS"
        />

        <meta property="og:title" content="Akila Suppliers" />
        <meta
          property="og:description"
          content="Explore safety items such as gloves, masks, and aprons."
        />
        <meta
          property="og:image"
          content="https://www.facebook.com/share/p/zHwm3b4w8JTcwHMb/?mibextid=qi2Omg"
        />
        <meta property="og:url" content="https://akilasuppliers.com/" />

        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3554835742821865"
          crossorigin="anonymous"
        ></script>
      </Helmet>

      <div className="max-w-6xl px-4 mx-auto sm:px-6 lg:px-8">
        {/* Page Header */}
        <header className="mb-8 text-center">
          <h1 className="text-3xl font-extrabold text-gray-800">
            Rubber Bands Collection-Low Prize with Quality Products-0717009059
          </h1>
          <p className="mt-2 text-gray-600">
            Explore our wide range of durable and versatile rubber bands.
          </p>
        </header>
        <div className="flex justify-center mb-8">
          <SearchBar onSearch={handleSearch} />
        </div>

        {/* Item Grid */}
        <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {filteredItems.length > 0 ? (
            filteredItems.map((item) => (
              <Link key={item.id} to={`/item/${item.id}`} className="group">
                <Item item={item} />
              </Link>
            ))
          ) : (
            <div className="text-center text-gray-600 col-span-full">
              No items available.
            </div>
          )}
        </div>

        {/* Routes */}
        <Routes>
          <Route path="/item/:id" element={<RuberDetail />} />
        </Routes>
      </div>

      {/* Scroll to Top Button */}
      {showScrollTopButton && (
        <button
          onClick={scrollToTop}
          className="fixed p-3 text-white transition duration-300 bg-blue-500 rounded-full shadow-lg bottom-4 right-4 hover:bg-blue-600"
        >
          ↑
        </button>
      )}
    </div>
  );
};

export default Ruber;
