// src/pages/AboutUs.js

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const services = [
  {
    id: 1,
    name: "Safety Gloves",
    description:
      "High-quality gloves including cotton gloves, surgical gloves, and rubber gloves. Available in various sizes and materials to suit your needs.",
  },
  {
    id: 2,
    name: "Rubber Bands",
    description:
      "Durable and elastic rubber bands available in multiple sizes. Ideal for industrial and everyday use.",
  },
  {
    id: 3,
    name: "Aprons & Protective Gear",
    description:
      "Comprehensive range of aprons and protective clothing designed to keep you safe in hazardous environments.",
  },
  {
    id: 4,
    name: "Face Masks",
    description:
      "Surgical Masks and Reusable Masks designed for safety and protection in medical and industrial environments.",
  },
  {
    id: 5,
    name: "Safety Boots",
    description:
      "Heavy-duty boots for various work environments.,Available in different styles, including rubber boots",
  },
  {
    id: 6,
    name: "Raincoats",
    description:
      "High-quality raincoats designed for workers in outdoor environments, providing protection from harsh weather conditions.",
  },
  {
    id: 7,
    name: "Serviettes",
    description:
      "Disposable and reusable serviettes for personal and industrial hygiene.",
  },
  {
    id: 8,
    name: "Goggles",
    description:
      "Protective goggles designed to shield eyes from harmful particles and bright light in industrial",
  },
  {
    id: 9,
    name: "Mops",
    description:
      "Industrial-grade mops for cleaning and sanitation in large commercial spaces.",
  },
  {
    id: 10,
    name: "All Types of Safety Items",
    description: "A range of additional safety items.",
  },

  // Add more services as needed
];

const teamMembers = [
  {
    id: 1,
    name: "U.G Ravindu Eshan",
    position: "Marketing Member",
    email: "ravinduesh@gamil.com",
  },
  {
    id: 2,
    name: "Ushan Sulochana",
    position: "Marketing Member",
    email: "ushansul@gmail.com",
  },
];
const AboutUs = () => {
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {

      try{
         (window.adsbygoogle = window.adsbygoogle || []).push({});
      }
      catch(e)
      {
        console.log(e);
      }
      if (window.scrollY > 200) {
        // Show button after scrolling down 200px
        setShowScrollTopButton(true);
      } else {
        setShowScrollTopButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="min-h-screen py-12 text-gray-100 bg-gray-100">
     
      <Helmet>
        <title>
          Akila Suppliers - Quality Safety Items | Gloves, Rubber Bands, Aprons
        </title>
        <meta
          name="description"
          content="Akila Suppliers offers high-quality safety items including gloves, rubber bands, aprons, raincoats, and more. Reliable service at affordable prices."
        />
        <meta
          name="keywords"
          content="safety items, gloves, rubber bands, aprons, protective gear, Akila Suppliers"
        />
        <meta name="robots" content="index,follow" />
        <meta
          property="og:title"
          content="Akila Suppliers - Quality Safety Items"
        />
        <meta
          property="og:description"
          content="Find top-notch safety items like gloves, rubber bands, and aprons at Akila Suppliers."
        />
        <meta property="og:image" content="https://example.com/og-image.jpg" />
        <meta property="og:url" content="https://akilasuppliers.com/about-us" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Akila Suppliers - Quality Safety Items"
        />
     
        <meta
          name="twitter:description"
          content="Shop for high-quality safety items at Akila Suppliers."
        />
        <meta
          name="twitter:image"
          content="https://example.com/twitter-image.jpg"
        />

        <meta property="og:title" content="Akila Suppliers" />
        <meta
          property="og:description"
          content="Explore safety items such as gloves, masks, and aprons."
        />
        <meta
          property="og:image"
          content="https://www.facebook.com/share/p/zHwm3b4w8JTcwHMb/?mibextid=qi2Omg"
        />
        <meta property="og:url" content="https://akilasuppliers.com/" />

        <script
          async
          src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3554835742821865"
          crossorigin="anonymous"
        ></script>
      </Helmet>

      <div className="max-w-6xl p-8 mx-auto bg-gray-800 rounded-lg shadow-lg">
        {/* About Us Section */}
        <h1 className="mb-8 text-5xl font-extrabold text-center text-yellow-300">
          About Us
        </h1>
        <p className="mb-6 text-lg">
          Welcome to Akila Suppliers! We are dedicated to providing high-quality
          products and exceptional service. Our team is passionate about
          ensuring you find exactly what you need.
        </p>
        <p className="mb-12 text-lg">
          Our mission is to deliver top-notch items and support to our
          customers. We believe in building long-term relationships and
          providing value through every interaction.
        </p>

        {/* Services Section */}
        <section className="mb-12">
          <h2 className="mb-6 text-4xl font-semibold text-center text-yellow-300">
            Our Services
          </h2>
        
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            {services.map((service) => (
              <div
                key={service.id}
                className="p-6 bg-gray-800 rounded-lg shadow-md"
              >
                <h3 className="mb-2 text-2xl font-bold text-blue-600 dark:text-yellow-400">
                  {service.name}
                </h3>

                <p className="text-gray-300">{service.description}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Team Members Section */}
        <section className="mb-12">
          <h2 className="mb-6 text-4xl font-semibold text-center text-yellow-300">
            Our Team
          </h2>
          <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
            {teamMembers.map((member) => (
              <div
                key={member.id}
                className="p-6 bg-gray-800 rounded-lg shadow-md"
              >
                <h3 className="mb-2 text-2xl font-semibold text-white">
                  {member.name}
                </h3>
                <p className="mb-2 text-gray-400">{member.position}</p>
                <a
                  href={`mailto:${member.email}`}
                  className="text-blue-400 hover:underline"
                >
                  {member.email}
                </a>
              </div>
            ))}
          </div>
        </section>

        {/* Contact Section */}
        <section>
          <h2 className="mb-6 text-4xl font-semibold text-center text-gray-300">
            Contact Us
          </h2>
          <div className="text-center">
            <p className="mb-4 text-lg">
              For inquiries, please reach out to us at:
            </p>
            <ul className="text-gray-300 list-none">
              <li className="mb-2">
                <a
                  href="tel:+94717009059"
                  className="px-4 py-2 text-white transition duration-300 bg-blue-500 rounded hover:bg-blue-600"
                >
                  Contact The One Member
                </a>
              </li>
              <li className="mb-2">
                Email:{" "}
                <a
                  href="mailto:akilasuppliers@gmail.com"
                  className="text-blue-400 hover:underline"
                >
                  akilasuppliers@gmail.com
                </a>
              </li>
              <li>Address: 75/1 Niripola, Hanwella</li>
            </ul>
          </div>
        </section>
      </div>
      {showScrollTopButton && (
        <button
          onClick={scrollToTop}
          className="fixed p-3 text-white transition duration-300 bg-blue-500 rounded-full shadow-lg bottom-4 right-4 hover:bg-blue-600"
        >
          ↑
        </button>
      )}
    </div>
  );
};

export default AboutUs;
